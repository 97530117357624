define("discourse/plugins/discourse-templates/discourse/components/d-templates/modal/form", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{i18n "templates.insert_template"}}
    class="d-templates d-templates-modal"
  >
    <:body>
      <DTemplates::FilterableList
        @textarea={{@model.textarea}}
        @onInsertTemplate={{@model.onInsertTemplate}}
        @onAfterInsertTemplate={{@closeModal}}
      />
    </:body>
  </DModal>
  */
  {
    "id": "StqqkQ0S",
    "block": "[[[8,[39,0],[[24,0,\"d-templates d-templates-modal\"]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"templates.insert_template\"],null]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@textarea\",\"@onInsertTemplate\",\"@onAfterInsertTemplate\"],[[30,2,[\"textarea\"]],[30,2,[\"onInsertTemplate\"]],[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"i18n\",\"d-templates/filterable-list\"]]",
    "moduleName": "discourse/plugins/discourse-templates/discourse/components/d-templates/modal/form.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});