define("discourse/plugins/discourse-templates/discourse/components/d-templates/tag-drop", ["exports", "@ember/object", "select-kit/components/tag-drop"], function (_exports, _object, _tagDrop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  let DTemplatesTagDrop = _exports.default = (_dec = (0, _object.computed)("availableTags.[]"), (_class = class DTemplatesTagDrop extends _tagDrop.default {
    get topTags() {
      // sort tags descending by count and ascending by name
      return (this.availableTags || []).sort((a, b) => {
        if (a.count !== b.count) {
          return b.count - a.count;
        } // descending
        if (a.name !== b.name) {
          return a.name < b.name ? -1 : 1;
        } // ascending
        return 0;
      });
    }
    search(filter) {
      return (this.content || []).map(tag => {
        if (tag.id && tag.name) {
          return tag;
        }
        return this.defaultItem(tag, tag);
      }).filter(tag => {
        if (filter == null) {
          return true;
        }
        const tagFilter = filter.toLowerCase();
        return tag.id.toLowerCase().includes(tagFilter) || tag.name.toLowerCase().includes(tagFilter);
      });
    }
    onChange(tagId, tag) {
      // overrides the action onChange of the parent with the value received in
      // the property onChangeSelectedTag in the handlebars template
      this.onChangeSelectedTag?.(tagId, tag);
    }
  }, (_applyDecoratedDescriptor(_class.prototype, "topTags", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "topTags"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChange"), _class.prototype)), _class));
});